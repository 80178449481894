import { graphql, StaticQuery, Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './header.module.scss'
import Logo from '../images/logo'

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => {
      let { title, menuLinks } = data.site.siteMetadata

      return (
        <header>
          <div
            className={styles.headerMain}
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `1.45rem 1.0875rem`
            }}
          >
            <div style={{ margin: 0 }}>
              <Link
                to='/'
                style={{
                  color: `white`,
                  textDecoration: `none`
                }}
              >
                <Logo />
                {/* <img
                  src={`/images/logo.svg`}
                  alt={siteTitle}
                  style={{ width: '150px', marginBottom: 0 }}
                /> */}
              </Link>
            </div>
            {menuLinks.length > 0 && (
              <nav className={styles.siteNav}>
                {menuLinks.map(item => {
                  let { name, link } = item

                  return (
                    <Link key={link} to={link} activeClassName={styles.active}>
                      {name}
                    </Link>
                  )
                })}
              </nav>
            )}
          </div>
        </header>
      )
    }}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
