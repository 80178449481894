import React from 'react'

const Footer = () => (
  <footer
    style={{
      width: '100%',
      maxWidth: 960,
      padding: `1.45rem 1.0875rem`,
      marginTop: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto'
    }}
  >
    © {new Date().getFullYear()}
  </footer>
)

export default Footer
